export default {
  //#region registercompany
  titleRegisterCompany: "Register new Company",
  nameBasic: "Basics",
  nameBusiness: "Business",
  companyAdministration: "Company Account",
  nameRepresent: "Represent",
  nameExecutive: "Executive",
  nameOwner: "Owner",
  namePublic: "Public",
  nameIdentity: "Identity check",
  nameConfirm: "Confirm Data",
  nameSuccess: "Success",
  //#endregion registercompany
  //#region basic
  basicPrimaryText: "Let's start with some basics",
  basicSecondaryText: "Select your location and businesstype",
  basicBusinessLocation: "Business Location",
  basicTypeOfBusiness: "Type of Business",
  basicBusinessStructureType: "Business structure type",
  //#endregion basic
  //#region business
  businessPrimaryText: "Describe your Company",
  businessSecondaryText:
    "Connect8 collects information to protect and support our customers as good as we can. Furthermore it is important to fulfill our Agreement.",
  businessAgreement: "Agreement",
  businessCompanyName: "Legal Companyname",
  businessCompanyID: "Company register ID",
  businessVAT: "VAT number (UID)",
  businessIndustryType: "Industry type",
  businessHomepage: "Homepage",
  //#endregion business
  //#region companyaccount
  companyAccountPrimaryText: "Create an Account for your Company",
  companyAccountSecondaryText:
    "You're using these details later to manage your company account",
  companyAccountEmail: "Company account mail",
  companyAccountPassword: "Company account password",
  //#endregion companyaccount
  //#region represent
  representPrimaryText: "Represent of the company",
  representSecondaryText:
    " We need someone who is legaly allowed to represent the company. If it is not you - stop here and call this person.",
  representFirstName: "Firstname",
  representLastName: "Lastname",
  representJobTitle: "Job title",
  representPhoneNumber: "Phone number",
  representOwnership: "Ownership percentage",
  representMailInfo: "This E-Mail will be your user login.",
  //endregion represent
  //#region executive
  executivePrimaryText: "Executive of the company",
  executiveSecondaryTextPart1:
    "Here you should enter the executives of your company. This is an optional step - ",
  executiveSecondaryTextPart2:
    "but if we get some sort of problems - we will try to check the national companyregister. If we find something suspicious we need to report this immediately.",
  executiveFirstName: "Firstname",
  executiveLastName: "Lastname",
  //#endregion executive
  //#region owner
  ownerPrimaryText: "Owner of the company",
  ownerSecondaryTextPart1:
    "Here you should enter the owners of your company. This is an optional step - ",
  ownerSecondaryTextPart2:
    "but if we get some sort of problems - we will try to check the national companyregister. If we find something suspicious we need to report this immediately.",
  ownerSecondaryTextPart3: "An Owner got at least 25% of the company.",
  ownerFirstName: "Firstname",
  ownerLastName: "Lastname",
  //#endregion owner
  //#region public
  publicPrimaryText: "Public information",
  publicSecondaryTextPart: "Enter some public information about your company.",
  publicPhoneNumber: "Customer support phone number",
  //#endregion public
  //#region identity
  identityPrimaryText: "Proof your identity",
  identitySecondaryTextPart1:
    "We want to protect our customers as good as we can. So we want to follow the",
  identitySecondaryTextPart2: "Know Your Customer(KYC)-Obligation",
  identitySecondaryTextPart3: "Therefore we need to approve your identity.",
  identitySecondaryTextPart4:
    "If you are on mobile, please use the phone horizontally to take the picture.",
  identityPassport: "Passport",
  identityNoWebcamTitle: "No webcam or ID document available?",
  identityNoWebcamText1:
    "You can complete the registration without identity verification.",
  identityNoWebcamText2:
    "We will contact you afterwards to carry out the identity verification.",
  identityNoWebcamButton: "Continue without identity verification",
  accordance: "Accordance",
  accordanceTooLow: "Accordance too low",
  accordanceTooLowInfo:
    "Please ensure that the image quality is sufficient and that you use a suitable ID.",
  //#endregion identity
  //#region confirm
  confirmPrimaryText: "Confirm data",
  confirmSecondaryText: "Please check your data and submit it",
  accountInfo: "Your user account will be created for",
  yourCompanyData: "Your company data",
  identityDone: "The identity check was done successfully.",
  identityNotDone: "The identity check is not finished yet.",
  identityNotDoneText:
    "We will contact you to execute the identity check manually.",
  //#endregion confirm
  //#region success
  successText:
    "Your registration is complete – We will review your data. After a successful review, you will receive an email, allowing you to use your account.",
  //#endregion success
  //#region errors
  errorRepresentMissing: "Data for Represent is missing!",
  errorIdentityDataMissing: "Data for Identity Check is missing!",
  errorCreateLogin: "The company data couldn't be created successfully",
  errorStepsMissing: "There are some steps missing!",
  errorConfirmationDataNotParseable:
    "An error occurred while processing your data. Please try again later.",
  errorUploadingPassport: "Error while uploading the passport",
  errorInvalidPassport: "Error: Passport is invalid or not readable",
  errorInvalidWebcamPhoto: "Error: Webcam picture invalid",
  errorUploadingWebcamPhoto: "Error while uploading the webcam picture",
  errorPassportUpload: "Error while uploading the passport",
  errorFaceComparison:
    "Error while comparing your ID and the webcam photo! Please make sure that each picture contains only one distinct face and that the image quality is sufficient",
  errorCompanyAccountDataMissing: "Data for company account is missing!",
  //#endregion errors
};
