export default {
  //#region registercompany
  titleRegisterCompany: "Neue Firma registrieren",
  nameBasic: "Grundlagen",
  nameBusiness: "Geschäft",
  companyAdministration: "Firmenkonto",
  nameRepresent: "Vertreter",
  nameExecutive: "Geschäftsführer",
  nameOwner: "Geschäftsinhaber",
  namePublic: "Öffentlich",
  nameIdentity: "Identitätsprüfung",
  nameConfirm: "Bestätigung der Daten",
  nameSuccess: "Erfolg",
  //#endregion registercompany
  //#region basic
  basicPrimaryText: "Beginnen wir mit einigen Grundlagen",
  basicSecondaryText: "Wählen Sie Ihren Standort und Unternehmenstyp aus",
  basicBusinessLocation: "Geschäftsstandort",
  basicTypeOfBusiness: "Geschäftsart",
  basicBusinessStructureType: "Art der Geschäftsstruktur",
  //#endregion basic
  //#region business
  businessPrimaryText: "Beschreiben Sie Ihr Unternehmen",
  businessSecondaryText:
    "Connect8 sammelt Informationen, um unsere Kunden so gut wie möglich zu schützen und zu unterstützen. Darüber hinaus ist es wichtig, unsere Vereinbarung zu erfüllen.",
  businessAgreement: "Vereinbarung",
  businessCompanyName: "Rechtlicher Firmenname",
  businessCompanyID: "Firmenbuchnummer",
  businessVAT: "UID",
  businessIndustryType: "Branchentyp",
  businessHomepage: "Webseite",
  //#endregion business
  //#region companyaccount
  companyAccountPrimaryText: "Erstellen Sie ein Konto für Ihre Firma",
  companyAccountSecondaryText:
    "Mit diesen Zugangsdaten können Sie später Ihr Firmenkonto verwalten.",
  companyAccountEmailEmail: "Firmenlogin E-Mail",
  companyAccountEmailPassword: "Firmenlogin password",
  //#endregion companyaccount
  //#region represent
  representPrimaryText: "Vertreter des Unternehmens",
  representSecondaryText:
    "Geben Sie hier die Daten der Person ein, die gesetzlich berechtigt ist, das Unternehmen zu vertreten. Für diese Person wird auch das erste Benutzerkonto Ihrer Firma erstellt.",
  representFirstName: "Vorname",
  representLastName: "Nachname",
  representJobTitle: "Stellenbezeichnung",
  representPhoneNumber: "Telefonnummer",
  representOwnership: "Eigentumsprozentsatz",
  representMailInfo: "Diese E-Mail wird für Ihr Benutzerkonto verwendet.",
  //endregion represent
  //#region executive
  executivePrimaryText: "Geschäftsführer des Unternehmens",
  executiveSecondaryTextPart1:
    "Hier sollten Sie die Führungskräfte Ihres Unternehmens eintragen. Dies ist ein optionaler Schritt - ",
  executiveSecondaryTextPart2:
    "Sollten jedoch Probleme auftreten, werden wir versuchen, das nationale Unternehmensregister zu überprüfen. Wenn wir etwas Verdächtiges finden, müssen wir dies sofort melden.",
  executiveFirstName: "Vorname",
  executiveLastName: "Nachname",
  //#endregion executive
  //#region owner
  ownerPrimaryText: "Eigentümer der Firma",
  ownerSecondaryTextPart1:
    "Hier sollten Sie die Inhaber Ihres Unternehmens eintragen. Dies ist ein optionaler Schritt - ",
  ownerSecondaryTextPart2:
    "Sollten jedoch Probleme auftreten, werden wir versuchen, das nationale Unternehmensregister zu überprüfen. Wenn wir etwas Verdächtiges finden, müssen wir dies sofort melden.",
  ownerSecondaryTextPart3:
    "Ein Eigentümer hat mindestens 25 % des Unternehmens erworben.",
  ownerFirstName: "Vorname",
  ownerLastName: "Nachname",
  //#endregion owner
  //#region public
  publicPrimaryText: "Öffentliche informationen",
  publicSecondaryTextPart:
    "Geben Sie einige öffentliche Informationen über Ihr Unternehmen ein.",
  publicPhoneNumber: "Telefonnummer des Kundensupports",
  //#endregion public
  //#region identity
  identityPrimaryText: "Beweisen Sie Ihre Identität",
  identitySecondaryTextPart1:
    "Wir wollen unsere Kunden bestmöglich schützen. Deshalb folgen wir dem ",
  identitySecondaryTextPart2: "Know Your Customer(KYC)-Verfahren",
  identitySecondaryTextPart3: "Daher müssen wir Ihre Identität bestätigen.",
  identitySecondaryTextPart4:
    "Wenn Sie ein Mobiltelefon verwenden, verwenden Sie das Telefon bitte horizontal, um das Bild aufzunehmen.",
  identityPassport: "Personalausweis",
  identityNoWebcamTitle: "Keine Webcam oder Ausweisdokument zur Hand?",
  identityNoWebcamText1:
    "Sie können die Registrierung auch ohne Identitätsprüfung abschließen.",
  identityNoWebcamText2:
    "Wir werden im Anschluss mit Ihnen Kontakt aufnehmen, um die Identitätsprüfung durchzuführen.",
  identityNoWebcamButton: "Ohne Identitätsprüfung fortsetzen",
  accordance: "Übereinstimmung",
  accordanceTooLow: "Übereinstimmung zu gering",
  accordanceTooLowInfo:
    "Bitte stellen Sie sicher, dass die Bildqualität ausreicht und Sie einen passenden Ausweis verwenden.",
  //#endregion identity
  //#region confirm
  confirmPrimaryText: "Bestätigung der Daten",
  confirmSecondaryText: "Bitte überprüfen und bestätigen Sie Ihre Daten.",
  accountInfo: "Ihr Benutzerkonto wird erstellt für",
  yourCompanyData: "Ihre Firmendaten",
  identityDone: "Die Identitätsüberprüfung wurde erfolgreich durchgeführt.",
  identityNotDone: "Die Identitätsüberprüfung wurde noch nicht durchgeführt.",
  identityNotDoneText:
    "Wir werden mit Ihnen Kontakt aufnehmen, um die Überprüfung abzuschließen.",
  //#endregion confirm
  //#region success
  successText:
    "Ihre Registrierung ist abgeschlossen – Wir werden ihre Daten prüfen. Nach erfolgreicher Prüfung werden sie eine E-Mail erhalten, sodass sie ihren Account verwenden können.",
  //#endregion success
  //#region errors
  errorRepresentMissing: "Die Daten des Firmenvertreters sind unvollständig!",
  errorIdentityDataMissing:
    "Die Daten für die Identitätsprüfung sind unvollständig!",
  errorCreateLogin:
    "Die Firmendaten konnten nicht erfolgreich erstellt werden!",
  errorStepsMissing: "Es wurden noch nicht alle Punkte abgeschlossen!",
  errorConfirmationDataNotParseable:
    "Beim Verarbeiten Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  errorUploadingPassport: "Fehler beim Hochladen des Ausweisdokuments!",
  errorInvalidPassport: "Fehler: Ausweis ist ungültig oder nicht lesbar",
  errorInvalidWebcamPhoto: "Fehler: Webcam Foto ungültig",
  errorUploadingWebcamPhoto: "Fehler beim Hochladen des Webcam Fotos!",
  errorPassportUpload: "Fehler beim Hochladen des Ausweises!",
  errorFaceComparison:
    "Beim Vergleich des Passfotos und des aufgenommenen Bildes ist ein Fehler aufgetreten. Achten Sie darauf, dass auf beiden Bildern ihr Gesicht gut erkennbar ist. Auf beiden Bildern darf nur ein Gesicht ersichtlich sein.",
  errorCompanyAccountDataMissing:
    "Die Daten für das Firmenkonto sind unvollständig!",
  //#endregion errors
};
