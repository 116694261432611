export default {
  // NotificationType
  NotificationType_default: "Standard",
  // TradeProductState
  TradeProductState_Active: "Active",
  TradeProductState_Deactivated: "Deactivated",
  // CompanyAddressType
  CompanyAddressType_Loading: "Loading",
  CompanyAddressType_Delivery: "Delivery",
  CompanyAddressType_Billing: "Billing",
  CompanyAddressType_AgreedPlace: "Agreed Place",
  // TagType
  TagType_String: "Text",
  TagType_Number: "Number",
  // UserTradeProductStatusType
  UserTradeProductStatusType_Open: "Open",
  UserTradeProductStatusType_PartialAcceptance: "Partial Acceptance",
  UserTradeProductStatusType_FullAcceptance: "Full Acceptance",
  UserTradeProductStatusType_Closed: "Closed",
  UserTradeProductStatusType_Expired: "Expired",
  // UserTradeProductDeliveryType
  UserTradeProductDeliveryType_NotSet: "Not Set",
  UserTradeProductDeliveryType_Self: "Self",
  UserTradeProductDeliveryType_Connect8: "Connect8",
  // PriceHistoryCreatorType
  PriceHistoryCreatorType_Creator: "Creator",
  PriceHistoryCreatorType_Bidder: "Bidder",
  // LoginType
  LoginType_User: "User",
  LoginType_InternalEmployee: "Internal Employee",
  // TaskType
  TaskType_CompanyActivation: "Company Activation",
  TaskType_JoinConnect8: "Join Connect8",
  TaskType_Bug: "Bug",
  TaskType_DevelopmentRequest: "Development Request",
  TaskType_GeneralRequest: "General Request",
  TaskType_BillingIssue: "Billing Issue",
  TaskType_PartnerRequest: "Partner Request",
  TaskType_JobRequest: "Job Request",
  // InvoiceState
  InvoiceState_Open: "Open",
  InvoiceState_Invoiced: "Invoiced",
  InvoiceState_Payed: "Payed",
  InvoiceState_Problem: "Problem",
  // InvoicePositionType
  InvoicePositionType_Trade: "Trade",
  InvoicePositionType_Delivery: "Delivery",
  InvoicePositionType_Manual: "Manual",
  // InvoiceStepStatus
  InvoiceStepStatus_None: "None",
  InvoiceStepStatus_StripeDone: "Stripe Done",
  InvoiceStepStatus_DMSUpload_one: "DMS Upload Done",
  InvoiceStepStatus_AllDone: "All Done",
  // NewsType
  NewsType_Alert: "Alert",
  NewsType_Default: "Default",
  // EventType
  EventType_Maintenance: "Maintenance",
  EventType_Stream: "Stream",
};
