export default {
  welcomeEmailSubject: "Ihr Account ist jetzt aktiviert – legen Sie los!",
  inviteToConnect8: "Einladung zu",
  accountActivatedTitle: "🎉 Ihr Account ist jetzt aktiviert",
  signupTextPart1:
    "Willkommen bei Connect8! 🎉 Ihr Account wurde erfolgreich aktiviert, und Sie können nun alle Funktionen unserer Anwendung nutzen.",
  signupTextPart2:
    "Falls Sie Fragen haben oder Unterstützung benötigen, steht unser Support-Team Ihnen jederzeit zur Verfügung.",
  signupTextPart3: "Viel Spaß beim Entdecken!",
  letsGo: "👉 Loslegen!",
  bestWishes: "Beste Grüße",
  registrationCompleteSubject:
    "Deine Registrierung ist abgeschlossen – Willkommen bei Connect8!",
  registrationCompleteTitle: "🎉 Ihre Registrierung ist bei uns eingegangen",
  registrationTextPart1:
    "Herzlichen Glückwunsch! Deine Registrierung bei Connect8 wurde erfolgreich abgeschlossen. Wir werden die Daten prüfen und aktivieren ihren Account sobald alles bestätigt wurde.",
  registrationTextPart2:
    "Falls Sie Fragen haben oder Unterstützung benötigen, steht unser Support-Team Ihnen jederzeit zur Verfügung. Hier nocheinmal die Auflistung der wichtigsten Daten: ",
  registrationTextPart3: "Viel Spaß mit Connect8!",
  registrationFirmdata: "Firmendaten",
  registrationRepresent: "Vertreter",
  invitationEmailSubject: "Einladung zur Nutzung von Connect8",
  invitationTitle: "🎉 Einladung zu Connect8 – Ihr Zugang wartet bereits!",
  invitationTextPart1:
    "wir freuen uns, Ihnen den Zugang zu Connect8 bereitzustellen! Mit Connect8 können Sie mit uns den Handel digitaler, intelligenter und grenzenloser gestalten.",
  invitationTextPart2:
    "Falls Sie Fragen haben oder Unterstützung benötigen, steht unser Support-Team Ihnen jederzeit zur Verfügung.",
  invitationTextPart3: "Wir freuen uns darauf, Sie an Bord zu haben! 🚀",
  notificationSubject: "🔥 Zeit zu handeln: Ein neues Angebot liegt vor!",
  notificationTitle:
    "🔥 Ihr neues Angebot ist da – Jetzt ansehen und entscheiden!",
};
