export default {
  signIn: "Loggen Sie sich ein",
  emailRequestSentSuccessfully: "Die Email wurde erfolgreich versendet",
  connect8DeliveryTitle: "Connect8 Lieferung",
  connect8DeliveryText:
    "Wollen Sie die Ware über das Connect8 Logistik Portal transportieren lassen?",
  selfDeliveryTitle: "Eigene Lieferung",
  selfDeliveryText: "Wollen Sie die Ware selbst liefern?",
  selfDeliverySuccess: "Die Lieferart wurde erfolgreich gesetzt",
  connect8DeliverySuccess: "Ihre Transportanfrage wurde erfolgreich erstellt!",
  tradeproductDeactivationSuccessfullyTitle: "Anfrage gelöscht",
  tradeproductDeactivationSuccessfullyText:
    "Ihre Produktanfrage wurde erfolgreich gelöscht",
  oneTimePassword: "Geben Sie das Einmal-Passwort ein",
  // ForgotPasswordMail
  forgotPasswordTitle: "Connect8 Passwort zurücksetzen",
  forgotPasswordVerificationCode: "Hier ist Ihr Verifikations Code",
  forgotPasswordLink: "oder klicken Sie auf den Link",
  forgotPasswordResetPassword: "Passwort zurücksetzen",
  // InvoiceMail
  invoiceMailSubject: "Ihre Rechnung",
  invoiceMailTitle: "Ihre Rechnung für",
  invoiceMailTextAttachmentInfo: "Anbei erhalten Sie ihre Rechnung.",
  invoiceMailText1:
    "Der Betrag wird automatisch mit ihrer hinterlegten Zahlungsmethode beglichen.",
  invoiceMailText2: "Vielen Dank für Ihr Vertrauen!",
  // SignupMail
  signupMailTitle: "Willkommen auf Connect8!",
  signupMailText1: "Ihr Konto wurde erfolgreich erstellt!",
  signupMailText2:
    "Wir werden Ihre eingegebenen Daten überprüfen und Ihr Konto aktivieren. Sollten die Daten unvollständig sein, werden wir Sie per E-Mail kontaktieren.",
  // Trade Confirm
  tradeConfirmTitle: "Wollen Sie den Handel wirklich abschließen?",
  tradeConfirmDescription:
    "Sie gehen mit diesem Schritt einen verbindlichen Vertrag ein. Bitte überprüfen Sie die AGB und weitere Unterlagen Ihres Geschäftpartners sorgfältig.",
  // logistic confirm
  logisticConfirmTitle: "Wollen Sie den Handel wirklich abschließen?",
  logisticConfirmDescription:
    "Sie gehen mit diesem Schritt einen verbindlichen Vertrag ein. Bitte überprüfen Sie die AGB und weitere Unterlagen Ihres Geschäftpartners sorgfältig.",
  // other
  tagEditorDescription:
    "Hier können Eigenschaften zur Anfrage angegeben werden, die die Suche und das Filtern nach Anfragen erleichtern. Zum Beispiel 'Farbe: rot'.",
  isEmailNotificationTooltip:
    "Aktivieren, um E-Mail Benachrichtigungen zu Reaktionen auf das Gebot zu bekommen",
  tradeProductDetailDone:
    "Diese Anfrage ist nicht mehr verfügbar. Weitere Gebote sind nicht mehr möglich.",
};
