export default {
  signIn: "Sign in to your account",
  emailRequestSentSuccessfully: "The mail was sent successfully",
  connect8DeliveryTitle: "Connect8 Delivery",
  connect8DeliveryText:
    "Do you want to have the goods transported via the Connect8 logistics portal?",
  selfDeliveryTitle: "Self Delivery",
  selfDeliveryText: "Do you want to deliver the goods yourself?",
  selfDeliverySuccess: "Delivery type was set successfully!",
  connect8DeliverySuccess: "Your logistic request was created successfully!",
  tradeproductDeactivationSuccessfullyTitle: "Request Deleted",
  tradeproductDeactivationSuccessfullyText:
    "Your product request was deleted successfully",
  oneTimePassword: "Enter the one time Password",
  // ForgotPasswordMail
  forgotPasswordTitle: "Connect8 Password Reset",
  forgotPasswordVerificationCode: "Here's your verification code",
  forgotPasswordLink: "Or click the link",
  forgotPasswordResetPassword: "Reset Password",
  // InvoiceMail
  invoiceMailSubject: "Your Invoice",
  invoiceMailTitle: "Your Invoice for",
  invoiceMailTextAttachmentInfo: "Please find attached your invoice",
  invoiceMailText1:
    "The amount will be deducted automatically from your default payment method.",
  invoiceMailText2: "Thank you for your trust!",
  // SignupMail
  signupMailTitle: "Welcome to Connect8!",
  signupMailText1: "Your account was created successfully!",
  signupMailText2:
    "We will validate the entered data and activate your account if everything is fine - otherwise we will contact you via E-Mail!",
  // Trade Confirm
  tradeConfirmTitle: "Do you really want to finalize the trade?",
  tradeConfirmDescription:
    "By taking this step, you are entering into a binding contract. Please review the terms and conditions (T&Cs) and other documents from your business partner carefully.",
  // Logistic Confirm
  logisticConfirmTitle: "Do you really want to finalize the trade?",
  logisticConfirmDescription:
    "By taking this step, you are entering into a binding contract. Please review the terms and conditions (T&Cs) and other documents from your business partner carefully.",
  // other
  tagEditorDescription:
    "You can add product properties here to optimize search and filter options for the requests. Example 'color: red'.",
  isEmailNotificationTooltip:
    "Get email notifications about reactions to your offer, if checked",
  tradeProductDetailDone:
    "This request is closed, no further bids can be submitted.",
};
