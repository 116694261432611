export default {
  // NotificationType
  NotificationType_default: "Standard",
  // TradeProductState
  TradeProductState_Active: "Aktiv",
  TradeProductState_Deactivated: "Inaktiv",
  // CompanyAddressType
  CompanyAddressType_Loading: "Ladestelle",
  CompanyAddressType_Delivery: "Lieferadresse",
  CompanyAddressType_Billing: "Rechnungsadresse",
  CompanyAddressType_AgreedPlace: "Bestimmungsort",
  // TagType
  TagType_String: "Text",
  TagType_Number: "Zahl",
  // UserTradeProductStatusType
  UserTradeProductStatusType_Open: "Offen",
  UserTradeProductStatusType_PartialAcceptance: "Teilweise akzeptiert",
  UserTradeProductStatusType_FullAcceptance: "Vollständig akzeptiert",
  UserTradeProductStatusType_Closed: "Abgeschlossen",
  UserTradeProductStatusType_Expired: "Abgelaufen",
  // UserTradeProductDeliveryType
  UserTradeProductDeliveryType_NotSet: "Nicht gewählt",
  UserTradeProductDeliveryType_Self: "Eigene Lieferung",
  UserTradeProductDeliveryType_Connect8: "Connect8 Lieferung",
  // PriceHistoryCreatorType
  PriceHistoryCreatorType_Creator: "Anzeigenersteller",
  PriceHistoryCreatorType_Bidder: "Bieter",
  // LoginType
  LoginType_User: "Benutzer",
  LoginType_InternalEmployee: "Interne/r Angestellte/r",
  // TaskType
  TaskType_CompanyActivation: "Firmenaktivierung",
  TaskType_JoinConnect8: "Connect8 beitreten",
  TaskType_Bug: "Programmfehler",
  TaskType_DevelopmentRequest: "Funktionsanfrage",
  TaskType_GeneralRequest: "Allgemeine Anfrage",
  TaskType_BillingIssue: "Zahlungsproblem",
  TaskType_PartnerRequest: "Partnerschaftsanfrage",
  TaskType_JobRequest: "Jobanfrage",
  // InvoiceState
  InvoiceState_Open: "Offen",
  InvoiceState_Invoiced: "Abgerechnet",
  InvoiceState_Payed: "Bezahlt",
  InvoiceState_Problem: "Problem",
  // InvoicePositionType
  InvoicePositionType_Trade: "Produkt",
  InvoicePositionType_Delivery: "Lieferung",
  InvoicePositionType_Manual: "Manuell",
  // InvoiceStepStatus
  InvoiceStepStatus_None: "Keine",
  InvoiceStepStatus_StripeDone: "Stripe abgeschlossen",
  InvoiceStepStatus_DMSUploadDone: "PDF Upload abgeschlossen",
  InvoiceStepStatus_AllDone: "Vollständig abgeschlossen",
  // NewsType
  NewsType_Alert: "Warnung",
  NewsType_Default: "Standard",
  // EventType
  EventType_Maintenance: "Wartung",
  EventType_Stream: "Stream",
};
