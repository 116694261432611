export default {
  submissionErrorSingle:
    "Beim Bestätigen Ihrer Daten ist ein Fehler aufgetreten",
  submissionErrorMultiple:
    "Beim Bestätigen Ihrer Daten sind COUNT Fehler aufgetreten",
  loadingErrorSingle: "Beim Laden der Daten ist ein Fehler aufgetreten",
  loadingErrorMultiple: "Beim Laden der Daten sind COUNT Fehler aufgetreten",
  oops: "Upps!",
  somethingWentWrong: "Etwas ist schiefgelaufen",
  allFieldsRequired: "Bitte alle Felder ausfüllen",
  pageNotFound: "Nicht gefunden",
  pageNotFoundText:
    "Sorry, wir konnten die von dir gesuchte Seite nicht finden.",
  unexpectedError: "Unerwarteter Fehler",
  unknownError: "Unbekannter Fehler",
  tradeProductUnavailable: "Das Angebot ist nicht mehr verfügbar",
  transportRequestUnavailable: "Die Anfrage ist nicht mehr verfügbar",
  fileUpload: "Fehler beim Hochladen der Datei",
  fileUploadNotSupportedType: "Fehler: Der Dateityp wird nicht unterstützt!",
  invalidBiddingSliderState: "Ungültiger Status",
  incotermNotAvailable: "Incoterm wurde nicht gefunden",
  userIsNoAdmin: "Aktueller Benutzer ist kein Admin!",
  fileDownload: "Fehler beim Herunterladen der Datei",
  filledFieldsOnAddList:
    "Achtung: Sie haben Felder befüllt, aber die Daten nicht zur Liste hinzugefügt. Bitte klicken Sie auf '+', um die Daten hinzuzufügen.",
  notFilledFields: "Bitte befüllen Sie alle Felder!",
  twoFACodeNotValid: "Der angegebene Code ist nicht gültig.",
};
