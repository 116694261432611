export default {
  headlineAboutUs:
    "We are transforming trade – digital, intelligent, boundless.",
  headlineTextAboutUs:
    "At Connect8, we are redefining the way businesses trade. Our mission is to make commerce more digital, intelligent, and boundless. By leveraging cutting-edge technology and data-driven solutions, we empower companies to streamline processes, optimize transactions, and unlock new market opportunities.",
  whoWeAreAboutUs:
    "We envision a world where trade knows no boundaries. By breaking down barriers and fostering digital connections, we create an ecosystem where businesses can thrive without limitations.",
  whatWeDoAboutUs:
    "We develop smart trading solutions that enable seamless interactions between businesses worldwide. Our platform offers efficiency, transparency, and scalability—helping our partners reduce costs, mitigate risks, and accelerate growth.",
  businessWasFounded: "Business was founded",
  peopleOnTheTeam: "People on the team",
  usersOnThePlatform: "Users on the platform",
  ourValuesAboutUs: "Our values",
  ourValuesTextAboutUs:
    "We would like to give you a brief overview of the values that are essential to our company philosophy.",
  beWorldClassAboutUs: "Be world-class",
  beWorldClassTextAboutUs:
    "We strive for excellence in everything we do, setting new benchmarks for quality and innovation.",
  takeResponsibilityAboutUs: "Take responsibility",
  takeResponsibilityTextAboutUs:
    "We act proactively, take ownership of our decisions, and stand by our commitments.",
  beSupportiveAboutUs: "Be supportive",
  beSupportiveTextAboutUs:
    "We work as a team, support each other, and foster a culture of trust and respect.",
  alwaysLearningAboutUs: "Always learning",
  alwaysLearningTextAboutUs:
    "We stay curious, continuously improve, and adapt to new challenges.",
  shareEverythingYouKnowAboutUs: "Share everything you know",
  shareEverythingYouKnowTextAboutUs:
    "We believe in openly sharing knowledge to grow together and drive innovation.",
  enjoyDowntimeAboutUs: "Enjoy downtime",
  enjoyDowntimeTextAboutUs:
    "We understand that rest is essential to maintaining productivity and creativity over the long term.",
  meetOurTeam: "Our Team",
  meetTextOurTeam: "Stronger together, with heart and passion!",
  headOfDevelopment: "Head of Development",
};
