export default {
  solutions: "Solutions",
  support: "Support",
  company: "Company",
  legal: "Legal",
  subscribeNewsletter: "Subscribe to our newsletter",
  newsletterText:
    "The latest news, articles, and resources, sent to your inbox weekly.",
  subscribe: "Subscribe",
  marketing: "Marketing",
  analytics: "Analytics",
  commerce: "Commerce",
  insights: "Insights",
  pricing: "Pricing",
  documentation: "Documentation",
  guides: "Guides",
  apiStatus: "API Status",
  about: "About",
  ourTeam: "Our Team",
  jobs: "Jobs",
  press: "Press",
  partners: "Partners",
  claim: "Forbidden Goods",
  privacy: "Privacy",
  terms: "Terms",
  impressum: "Impressum",
  emailUs: "Contact via mail",
};
