export default {
  solutions: "Lösungen",
  support: "Support",
  company: "Unternehmen",
  legal: "Rechtliches",
  subscribeNewsletter: "Abonniere unseren Newsletter",
  newsletterText:
    "Die neuesten Nachrichten, Artikel und Ressourcen, wöchentlich an Ihr Postfach gesendet.",
  subscribe: "Abonnieren",
  marketing: "Marketing",
  analytics: "Analysen",
  commerce: "Handel",
  insights: "Einblicke",
  pricing: "Preise",
  documentation: "Dokumentation",
  guides: "Anleitungen",
  apiStatus: "API Status",
  about: "Über Uns",
  ourTeam: "Unser Team",
  jobs: "Jobs",
  press: "Presse",
  partners: "Partner",
  forbidden: "Verbotene Ware",
  privacy: "Datenschutz",
  terms: "AGB",
  impressum: "Impressum",
  emailUs: "Kontakt via Mail",
};
