export default {
  save: "Speichern",
  confirm: "Bestätigen",
  back: "Zurück",
  yes: "Ja",
  cancel: "Abbrechen",
  confirmDeactivationTitle: "Deaktivierung bestätigen?",
  confirmDeactivationText:
    "Sind Sie sicher, dass Sie Ihr Konto deaktivieren und alle Daten löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!",
  confirmDeactivationButton: "Ja, Konto löschen",
  password: "Passwort",
  passwordChangeSuccess: "Passwort wurde erfolgreich geändert!",
  profileChangeSuccess: "Profildaten wurden erfolgreich geändert!",
  languageChangeSuccess: "Die Spracheinstellungen wurden erfolgreich geändert!",
  changePassword: "Passwort ändern",
  changePasswordText: "Ändern Sie das Passwort Ihres Kontos.",
  currentPassword: "Aktuelles Passwort",
  newPassword: "Neues Passwort",
  confirmPassword: "Neues Passwort bestätigen",
  deleteAccount: "Konto löschen",
  deleteAccountText:
    "Wollen Sie unseren Dienst nicht länger verwenden? Sie können Ihr Konto hier löschen. Diese Aktion kann nicht rückgängig gemacht werden!",
  companyInformation: "Firmendaten",
  userInformation: "Benutzerdaten",
  companyInformationText: "Hier können Daten zur Firma geändert werden.",
  userInformationText: "Hier können Daten zum User geändert werden.",
  companyLanguageInformation: "Sprachen",
  companyLanguageInformationText:
    "Hier können Spracheinstellungen geändert werden",
  allowedFileTypes: "JPG, GIF oder PNG. max. 1MB",
  companyName: "Firmenname",
  street: "Straße",
  place: "Ort",
  country: "Land",
  zip: "PLZ",
  vat: "UID-Nummer",
  email: "E-Mail",
  uploadAFile: "Datei hochladen",
  account: "Konto",
  users: "Benutzer",
  userUpdatedSuccessfully: "Benutzer wurde erfolgreich aktualisiert!",
  userCreatedSuccessfully: "Benutzer wurde erfolgreich erstellt!",
  companyAddressUpdatedSuccessfully: "Adresse wurde erfolgreich aktualisiert!",
  companyAddressCreatedSuccessfully: "Adresse wurde erfolgreich erstellt!",
  userAccounts: "Benutzerkonten",
  forename: "Vorname",
  lastname: "Nachname",
  position: "Position",
  edit: "Bearbeiten",
  active: "Aktiv",
  isPublic: "Öffentliches Konto",
  editUser: "Konto bearbeiten",
  registerUser: "Neuer Benutzer",
  registerNewUser: "Neues Benutzerkonto registrieren",
  editCompanyAddress: "Adresse bearbeiten",
  registerNewCompanyAddress: "Neue Adresse hinzufügen",
  shop: "Shop",
  connect8trading: "Connect8 Handel",
  openProductRequests: "Offene Anfragen",
  tradeOverview: "Übersicht",
  tradeMyRequests: "Meine Anfragen",
  tradeMyOffers: "Meine Angebote",
  tradeRejected: "Abgelehnt",
  tradeExpired: "Abgelaufen",
  tradeDelivery: "Meine Zuschläge",
  tradeDoneInDelivery: "Lieferung ausstehend",
  tradeDoneInDeliverySelf: "eigene Lieferungen",
  tradeDoneInDeliveryConnect8: "Connect8 Lieferungen",
  tradeComplete: "Abgeschlossen",
  newProductRequest: "Neue Handelsanfrage",
  connect8Logistic: "Connect8 Logistik",
  openLogisticRequests: "Offene Anfragen",
  myLogisticRequests: "Meine Anfragen",
  myLogisticOffers: "Meine Angebote",
  doneLogistics: "Abgeschlossene Lieferungen",
  pastLogistics: "Vergangene Lieferungen",
  faq: "FAQ",
  management: "Verwaltung",
  salesdashboard: "Dashboard",
  invoicesoverview: "Rechnungen",
  logout: "Abmelden",
  tasks: "Aufgaben",
  taskschema: "Aufgabenschema",
  yourQuickfilters: "Schnellfilter",
  addressManagement: "Adressen Verwaltung",
  deliveryAddress: "Lieferadresse",
  delivery_address_id: "Lieferadresse",
  billingAddress: "Rechnungsadresse",
  loadingAddress: "Ladestelle",
  deliveryAddresses: "Lieferadressen",
  billingAddresses: "Rechnungsadressen",
  loadingAddresses: "Ladestellen",
  agreedplaceAddresses: "Bestimmungsorte",
  submit: "Bestätigen",
  skip: "Überspringen",
  loading: "Lädt",
  processing: "Verarbeite",
  path: "Pfad",
  processingData: "Daten werden verarbeitet",
  emailSentSuccessfully: "Email wurde erfolgreich versendet",
  paymentMethod: "Zahlungsmethoden",
  incoterm: "Incoterm",
  loadingDate: "Ladedatum",
  loadingDateFrom: "Ladedatum ab",
  loadingDateTo: "Ladedatum bis",
  deliveryDate: "Lieferdatum",
  deliveryDateFrom: "Lieferdatum ab",
  deliveryperiodfrom: "Lieferdatum ab",
  deliveryDateTo: "Lieferdatum bis",
  deliveryperiodto: "Lieferdatum bis",
  state: "Status",
  price: "Preis",
  message: "Nachricht",
  faqOffer: "FAQ zur Angeboten",
  faqDelivery: "FAQ zur Lieferung",
  faqDone: "FAQ zu abgeschlossenen Händel",
  goToOverview: "Zur Übersicht",
  desiredPricePerUnit: "Wunschpreis/Einheit (€)",
  description: "Beschreibung",
  send: "Senden",
  selfDelivery: "Eigene Lieferung",
  connect8Delivery: "Connect8 Logistik",
  tradeCounter: "Gegenangebot",
  tradeDecline: "Ablehnen",
  tradeAccept: "Akzeptieren",
  tradePriceCorrection: "Preiskorrektur",
  close: "Schließen",
  existingOfferText:
    "Sie haben bereits Angebote zu dieser Anzeige erstellt. Wechseln Sie in die Übersicht, um weitere Angebote zu erstellen.",
  confirmPaymentMethodActivationTitle: "Zahlungsmethode bestätigen?",
  confirmPaymentMethodActivationText:
    "Mit dem Akzeptieren dieses Dialoges erkennen sich sich mit der Zahlungsvereinbarung und dem Zahlungsmodell einverstanden. Sie können diesen jederzeit widerrufen wie in der Zahlungsvereinbarung angegeben.",
  confirmPaymentMethodDeletionTitle: "Zahlungsmethode löschen?",
  confirmPaymentMethodDeletionText:
    "Sind Sie sicher, dass Sie diese Zahlungsmethode löschen möchten? Dies kann nicht rückgängig gemacht werden!",
  confirmTradeProductDeletionTitle: "Produktanfrage löschen?",
  confirmTradeProductDeletionText:
    "Sind Sie sicher, dass Sie Ihre Produktanfrage löschen möchten?",
  existingOfferTextForMyTradeProduct:
    "Es wurden bereits Angebote zu dieser Anzeige erstellt. Wechseln Sie in die Übersicht, um sie zu prüfen.",
  noOfferText: "Zu dieser Anzeige wurden noch keine Angebote abgegeben.",
  default: "Standard",
  isemailnotification: "E-Mail Benachrichtigungen",
  tutorial: "Anleitung",
  loadingplace: "Ladestelle",
  unloadingplace: "Entladestelle",
  goods: "Ware",
  details: "Details",
  makeOffer: "Angebot",
  downloadAttachments: "Download",
  firststep: "Erste Schritte",
  firststepdescription:
    "Hier werden ihnen die ersten Schritte vorgestellt - einige sind die Voraussetzung für die komplette Nutzbarkeit der Plattform.",
  addressType: "Typ der Adresse",
  noPaymentMethodAvailableCantBid:
    "In ihrem Firmenaccount wurde noch keine Zahlungsmethode oder eine Abrechnungsadresse hinterlegt. Stellen sie sicher, dass eine gültige Zahlungsmethode und eine Abrechnungsadresse hinterlegt wurde, bevor sie ein Angebot abgeben.",
  validDateUntil: "Angebot gültig bis",
  offerNotValidAnymore: "Dieses Angebot ist nicht mehr gültig.",
  no: "Nr",
  productCategory: "Warenart",
  product_category_id: "Warenart",
  quantityUnit: "Anzahl/Einheit",
  goodsValue: "Warenwert",
  weightNetGross: "Gewicht (netto/brutto)",
  company: "Firma",
  contactPerson: "Ansprechperson",
  createdAt: "Erstellt am",
  lastPrice: "Letzter Preis",
  notificationFrom: "Benachrichtigung von",
  hello: "Hallo",
  invitationText:
    "Du wurdest eingeladen auf Connect8 zu handeln! Du kannst unten auf den Link klicken und wirst direkt bei dem Produkt landen, solltest du allerdings noch keinen Account haben, musst du dich zuerst registrieren.",
  source: "Quelle",
  currentPrice: "Aktueller Preis",
  currentPriceTotal: "Gesamtpreis",
  validUntil: "Gültig bis",
  loadingFrom: "Laden ab",
  loadingTo: "Laden bis",
  deliveryFrom: "Liefern ab",
  deliveryTo: "Liefern bis",
  weightNet: "Gewicht netto (kg)",
  weightGross: "Gewicht brutto (kg)",
  quantity: "Anzahl",
  unit: "Einheit",
  unit_id: "Einheit",
  loadingMeter: "Lademeter",
  cubicMeter: "Kubikmeter",
  length: "Länge (cm)",
  width: "Breite (cm)",
  height: "Höhe (cm)",
  companyDetails: "Firmendetails",
  homepage: "Homepage",
  videoURL: "Video ID",
  shortDescription: "Kurze Beschreibung",
  offer: "Angebot",
  offers: "Angebote",
  noResultFilter: "Keine Ergebnisse... Ändere deine Filterung",
  noResult: "Kein Eintrag",
  actualInvoices: "Offene Rechnungen",
  archivedInvoices: "Archivierte Rechnungen",
  problemInvoices: "Nicht abrechenbare Rechnungen",
  activateCompany: "Firma aktivieren",
  joinConnect8: "Kontaktiere folgende Person",
  invoiceType: "Art",
  date: "Datum",
  amount: "Betrag",
  tax: "Steuersatz",
  taxAmount: "Steuerbetrag",
  invoice: "Rechnung",
  Invoices: "Rechnungen",
  InvoicesDescription:
    "Hier finden Sie eine Auflistung der Rechnungen Ihrer Firma",
  text2FA:
    "Zwei-Faktor-Authentifizierung (2FA) ist ein Sicherheitsmechanismus, der eine zusätzliche Schutzschicht hinzufügt, um Ihre Online-Konten zu sichern. Neben dem Passwort, das Sie verwenden, verlangt 2FA einen zweiten Verifizierungsschritt. Dies kann ein Einmalpasswort sein, das an Ihr Mobiltelefon gesendet wird, eine App wie Google Authenticator oder ein physisches Sicherheitsgerät.",
  security2FA:
    "Durch 2FA wird es für potenzielle Angreifer schwieriger, auf Ihr Konto zuzugreifen, selbst wenn sie Ihr Passwort kennen. Es bietet somit einen effektiveren Schutz gegen Hacking und unbefugten Zugriff auf sensible Informationen.",
  logisticRequestTitle: "Transport Anfragen",
  logisticRequestDescription: "Hier finden Sie alle offenen Transportanfragen",
  productImage: "Produktbild",
  productDescription: "Produktbeschreibung",
  productProperties: "Produkteigenschaften",
  deliveryInformation: "Lieferinformationen",
  documents: "Dokumente",
  title: "Bezeichnung",
  createRequest: "Anfrage erstellen",
  updateRequest: "Anfrage speichern",
  forgotPassword: "Passwort vergessen",
  forgotPasswordText:
    "Keine Sorge, wir senden dir Informationen für die Zurücksetzung per Mail.",
  backToLogin: "Zurück zum Login",
  checkYourEMail: "Überprüfe deine E-Mail",
  checkYourEMailText: "Wir haben dir einen Code zur Verifizierung gesendet.",
  passwordReset: "Passwort ändern",
  dontWorry: "Keine Sorge. Das passiert öfters.",
  skipAll: "Oder alle auf erledigt setzen",
  attachment: "Anhang",
  attachments: "Anhänge",
  loadingMessage: "Lädt...",
  none: "keine",
  chooseRoleTitle: "Wähle deine Rolle/n",
  chooseRoleText:
    "Hier kannst du als erstes festlegen, welche Rolle deine Firma in unserer App annehmen soll - dies kannst du jederzeit ändern in den Einstellungen deines Firmenaccounts.",
  buyer: "Käufer",
  createTrades: "Stelle Handelsanfragen",
  featureActivation: "Folgende Features werden freigeschaltet:",
  seller: "Verkäufer",
  carrier: "Spedition",
  createOffer: "Erstelle Angebote für den Handel",
  completeRoleSelection: "Rollenauswahl abschließen",
  createTransportOffer: "Erstelle Angebote für die Transporte",
  news: "Neuigkeiten",
  newsText:
    "Wir versuchen euch mit einigen relevanten Neuigkeiten auf dem Laufenden zu halten!",
  importantTasks: "Wichtige Aufgaben",
  importantTasksText:
    "Diese Aufgaben müssen erledigt werden, um alle Funktionalitäten freizuschalten!",
  upcomingEvents: "Bevorstehende Events",
  upcomingEventsText:
    "Informationen über bevorstehende Updates, Wartungen und vieles mehr!",
  createDeliveryTask: "Lieferadresse anlegen",
  createDeliveryTaskText: "Es muss eine aktive Lieferadresse hinterlegt sein!",
  createPaymentTask: "Zahlungsmethode anlegen",
  createPaymentTaskText: "Es muss eine aktive Zahlungsmethode hinterlegt sein!",
  createBillingTask: "Rechnungsadresse anlegen",
  createBillingTaskText:
    "Es muss eine aktive Rechnungsadresse hinterlegt sein!",
  createLoadingTask: "Ladeadresse anlegen",
  createLoadingTaskText: "Es muss eine aktive Ladeadresse hinterlegt sein!",
  total: "gesamt",
  popular: "Beliebt",
  newest: "Neueste",
  lowestPrice: "Niedrigster Preis",
  highestPrice: "Höchster Preis",
  filter: "Filter",
  filters: "Filter",
  clearAll: "Alle entfernen",
  sort: "Sortieren",
  qrCode: "QR Code",
  login: "Login",
  register: "Registrieren",
  yourProfile: "Ihr Profil",
  selectOption: "Option auswählen...",
  accountLanguage: "Kontosprache",
  tradeProductLanguages: "Sprachen für Produktanfragen",
  pleaseSelectAValue: "Bitte wählen Sie einen Wert",
  webcamNotFoundInfo:
    "Keine Webcam gefunden. Bitte verbinden Sie ein Gerät und aktualisieren Sie die Seite.",
  webcamStart: "Bild aufnehmen",
  webcamStop: "Aufnahme beenden",
  webcamCapture: "Aufnahme",
  webcamDelete: "Aufnahme löschen",
  exwBuyer:
    "Kosten und Risiken ab Warenbereitstellung, Aus-,Durch- und Einfuhr",
  exwSeller: "Kosten und Risiken bis Warenbereitstellung",
  fcaBuyer: "Kosten und Risiken ab dem 1. Frachtführer, Durch- und Einfuhr",
  fcaSeller: "Kosten und Risiken bis zu dem 1. Frachtführer, Ausfuhr",
  cptBuyer:
    "Risiken ab zum 1. Frachtführer, Kosten ab zum Bestimmungsort, Einfuhr",
  cptSeller:
    "Risiken bis zum 1. Frachtführer, Kosten bis zum Bestimmungsort, Aus- und Durchfuhr",
  cipBuyer:
    "Risken ab dem 1. Frachtführer, Kosten ab dem Bestimmngsort, Einfuhr",
  cipSeller:
    "Risiken bis zum 1. Frachtführer, Kosten bis zum Bestimmungsort, Aus- und Durchfuhr, Transportversicherung ab 1.FF bis zum Bestimmungsort",
  dapBuyer:
    "Kosten und Risiken ab Ware steht am Bestimmungsort auf LKW bereit, Einfuhr",
  dapSeller:
    "Kosten und Risiken bis Ware am Bestimmungsort auf LKW bereit steht, Aus- und Durchfuhr",
  datBuyer:
    "Kosten und Risiken ab Ware liegt am Terminal oder am vereinbarten Ort, Einfuhr",
  datSeller:
    "Kosten und Risiekn bis Ware liegt am Terminal oder am vereinbarten Ort, Aus- und Durchfuhr",
  ddpBuyer: "Kosten und Risken ab Bestimmungsort entladebereit",
  ddpSeller:
    "Kosten und Risiken bis Bestimmungsort unentladen, Aus-, Ein- und Durchfuhr",
  fasBuyer:
    "Kosten und Risiken ab Länggseite Schiff im Hafen, Ein- und Durchfuhr",
  fasSeller: "Kosten und Risiken bis Länggseite Schiff im Hafen, Ausfuhr",
  fobBuyer:
    "Kosten und Risiken ab an Bord des Schiffes im Hafen, Ein- und Durchfuhr",
  fobSeller: "Kosten und Risiken bis an Bord des Schiffes im Hafen, Ausfuhr",
  cfrBuyer:
    "Kosten ab Bestimmungshafen, Risken ab an Bord des Schiffes, Ein- und Durchfuhr",
  cfgSeller:
    "Kosten bis Bestimmungshafen, Risken bis an Bord des Schiffes, Ausfuhr",
  cifBuyer:
    "Kosten ab Bestimmungshafen, Risiken ab an Bord des Schiffes, Ein- und Durchfuhr",
  cifSeller:
    "Kosten bis Bestimmungshafen, Risken bis an Bord des Schiffes, Ausfuhr, Seetransportversicherung",
  greenExplanationIncoterm:
    "Es sind individuelle Vereinbarungen bzgl. Bestimmungsort möglich, daher kann der Bestimmungsort zwischen 'An Bord des Schiffes im Bestimmungshafen' und 'entladebereit auf dem LKW liegen'.",
  agreedAddress: "Bestimmungsort",
  redExplanationIncoterm: "Achtung! Anderer Bestimmungsort wurde angegeben!",
  destination: "Lieferort",
  showMore: "Mehr anzeigen",
  titleContains: "Titel enthält",
  category: "Kategorie",
  markedAsFavorite: "Favorit",
  quickfilterFavorites: "Favorit",
  actionNeeded: "Aktion nötig",
  quickfilterActionNeeded: "Aktion nötig",
  quickfilterPrice: "Preis",
  quickFilterFrom: "ab",
  quickFilterUpTo: "bis",
  twoFATitle: "Zwei Phasen Authentifizierung",
  twoFAEnabled: "Du hast die Zwei Phasen Authentifizierung aktiviert.",
  twoFAEnable: "Aktivieren",
  twoFADisabled: "Du hast die Zwei Phasen Authentifizierung deaktiviert.",
  twoFADisable: "Deaktivieren",
  twoFABottomExplanation:
    "Scanne diesen QR-Code mit deiner Authentifizierungs-App. Wenn du den QR-Code nicht scannen kannst, kannst du dieses Konto manuell in deiner Authentifizierungs-App hinzufügen, indem du diesen Code verwendest:",
  admin: "Admin",
  newemployee: "Neuer interner Benutzer",
  accounts: "Konten",
  redis: "Redis",
  language: "Sprache",
  companyAdmin: "Administrator",
  companySettings: "Firmeneinstellungen",
  proceedAnyway: "Trotzdem fortfahren",
  provideUID: "EU Länder müssen eine UID Nummer angeben.",
  exactTaxName:
    "Der genaue Firmenname, welcher bei der Steuernummernregistrierung angegeben wurde.",
  emailToSendOffer: "Bitte Empfänger E-Mail des Angebotes angeben.",
  vatRegNoNotValid:
    "In ihrem Firmenaccount wurde keine gültige UID Nummer hinterlegt. Stellen sie sicher, dass eine gültige UID Nummer hinterlegt wurde, bevor sie ein Angebot abgeben.",
  userList: "Benutzerliste",
  noElements: "keine Elemente",
  first_name: "Vorname",
  last_name: "Nachname",
  loadingDates: "Laden",
  deliveryDates: "Liefern",
  contact: "Kontakt",
  to: "nach",
  key: "Bezeichnung",
  value: "Wert",
  chooseFile: "Datei auswählen",
  noFileChosen: "Keine Datei ausgewählt",
  profilePicture: "Profilbild",
  companyLogo: "Firmenlogo",
  invoicetitle: "Rechnung erstellen",
  invoicetextandfields:
    "Hier können manuelle Rechnungspositionen erstellt werden. Diese landen auf der normalen Monatsrechnung - oder erstellen eine neue Rechnung sollte nichts bestehendes vorhanden sein.",
  invoicetext:
    "WICHITG: Firma muss PAYMENTMETHOD und BILLINGADDRESS hinterlegt haben, sonst bekommen wir Fehler!",
  createInvoice: "Rechnung erstellen",
};
