export default {
  welcomeEmailSubject: "Your Account was activated! – let's go!",
  inviteToConnect8: "Invitation to",
  accountActivatedTitle: "🎉 Your Account is now activated",
  signupTextPart1:
    "Welcome to Connect8! 🎉 Your account has been successfully activated, and you can now enjoy all the features of our platform.",
  signupTextPart2:
    "If you have any questions or need assistance, our support team is always here to help.",
  signupTextPart3: "Enjoy exploring!",
  letsGo: "👉 Lets Go!",
  bestWishes: "Best regards",
  registrationCompleteSubject:
    "Your registration is complete – Welcome to Connect8!",
  registrationCompleteTitle: "🎉 Your registration has been received.",
  registrationTextPart1:
    "Congratulations! Your registration with Connect8 has been successfully completed. We will review the data and activate your account as soon as everything is confirmed.",
  registrationTextPart2:
    "If you have any questions or need assistance, our support team is always available for you. Here is a summary of the most important data:",
  registrationTextPart3: "Have fun with Connect8!",
  registrationFirmdata: "Company data",
  registrationRepresent: "Represent",
  invitationEmailSubject: "Invitation to Join Connect8",
  invitationTitle: "🎉 Invitation to Connect8 – Your Access is Ready!",
  invitationTextPart1:
    "We are excited to provide you with access to Connect8! With Connect8, you can work with us to make commerce more digital, smarter, and borderless.",
  invitationTextPart2:
    "If you have any questions or need assistance, our support team is always here to help.",
  invitationTextPart3: "We look forward to having you on board! 🚀",
  notificationSubject: "🔥 Time to act: A new offer has been submitted!",
  notificationTitle:
    "🔥 Your new offer has arrived – Check it out and take action!",
};
