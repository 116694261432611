export default {
  headlineAboutUs:
    "Wir verändern den Handel – digital, intelligent, grenzenlos.",
  headlineTextAboutUs:
    "Bei Connect8 definieren wir die Art und Weise, wie Unternehmen handeln, neu. Unsere Mission ist es, den Handel digitaler, intelligenter und grenzenlos zu gestalten. Durch den Einsatz modernster Technologie und datengesteuerter Lösungen ermöglichen wir es Unternehmen, Prozesse zu optimieren, Transaktionen zu verbessern und neue Marktchancen zu erschließen.",
  whoWeAreAboutUs:
    "Wir stellen uns eine Welt vor, in der Handel keine Grenzen kennt. Indem wir Barrieren abbauen und digitale Verbindungen fördern, schaffen wir ein Ökosystem, in dem Unternehmen ohne Einschränkungen florieren können.",
  whatWeDoAboutUs:
    "Wir entwickeln intelligente Handelslösungen, die nahtlose Interaktionen zwischen Unternehmen weltweit ermöglichen. Unsere Plattform bietet Effizienz, Transparenz und Skalierbarkeit – und hilft unseren Partnern, Kosten zu senken, Risiken zu minimieren und ihr Wachstum zu beschleunigen.",
  businessWasFounded: "Firma wurde gegründet",
  peopleOnTheTeam: "Mitarbeiter",
  usersOnThePlatform: "Nutzer der Plattform",
  ourValuesAboutUs: "Unsere Werte",
  ourValuesTextAboutUs:
    "Wir möchten euch ein kleinen Überblick über die Werte geben, die unabdinglich für unsere Firmenphilosophie sind.",
  beWorldClassAboutUs: "Sei erstklassig",
  beWorldClassTextAboutUs:
    "Wir streben nach Exzellenz in allem, was wir tun, und setzen neue Maßstäbe für Qualität und Innovation.",
  takeResponsibilityAboutUs: "Übernimm Verantwortung",
  takeResponsibilityTextAboutUs:
    "Wir handeln proaktiv, übernehmen Verantwortung für unsere Entscheidungen und stehen zu unseren Verpflichtungen.",
  beSupportiveAboutUs: "Sei unterstützend",
  beSupportiveTextAboutUs:
    "Wir arbeiten als Team, unterstützen uns gegenseitig und schaffen eine Kultur des Vertrauens und Respekts.",
  alwaysLearningAboutUs: "Lerne immer weiter",
  alwaysLearningTextAboutUs:
    "Wir bleiben neugierig, entwickeln uns kontinuierlich weiter und passen uns neuen Herausforderungen an.",
  shareEverythingYouKnowAboutUs: "Teile dein Wissen",
  shareEverythingYouKnowTextAboutUs:
    "Wir glauben an den offenen Austausch von Wissen, um gemeinsam zu wachsen und Innovation zu fördern.",
  enjoyDowntimeAboutUs: "Genieße Auszeiten",
  enjoyDowntimeTextAboutUs:
    "Wir wissen, dass Erholung wichtig ist, um nachhaltig produktiv und kreativ zu bleiben.",
  meetOurTeam: "Unser Team",
  meetTextOurTeam: "Gemeinsam stark, mit Herz und Leidenschaft!",
  headOfDevelopment: "Head Of Development",
};
